/* eslint-disable */
import React from 'react'
import { graphql } from 'gatsby'

import womanLaptopWriting from '../../images/people/woman-on-laptop-writing-in-notebook.jpg'

import { StaticImage } from 'gatsby-plugin-image'
import CountdownTimer from '../../components/Countdown'

import {
  AvailabilityForm,
  Columns,
  Column,
  LinkButton,
  SplitContent,
  Stack,
  Typography,
  VariableContent,
  useMapi,
} from '@leshen/gatsby-theme-leshen'
import { Layout } from '@leshen/gatsby-theme-contentful'

import footer from '../../components/FooterPPC'
import header from '../../components/HeaderPPC'

const Home = ({ data }) => {
  const { requestId, rotatedNumber } = useMapi()

  const brandyList = ['disclaimer-aet-3stepiteration']

  const layoutProps = {
    data: {
      contentfulPage: {
        header: header(),
        footer: footer(brandyList, data?.allBrandyDisclaimer?.edges),
        seo: {
          title: ' Aetna Medicare ',
          description: '',
          canonical: 'https://www.aetnamedicaredirect.com/',
          robots: 'follow,index',
        },
        path: '/lp/3-steps-direct',
        promoCode: 'SAMPLEPROMO',
        ringpool: 'MED',
        hideStickyCTA: false,
        spanish: false,
        sections: [
          {
            // Add any relevant section data
          },
        ],
      },
      site: {
        siteMetadata: {
          siteURL: 'https://www.aetnamedicaredirect.com/',
          siteName: 'aetnamedicaredirect',
          alternateName: 'aetnamedicaredirect',
          removeTrailingSlashes: true,
        },
      },
    },
    titleTemplate: 'Aetna Medicare ',
    defaultRobots: 'nofollow,noindex',
    main: (
      <>
        <VariableContent backgroundColor="primary">
          <Stack>
            <Columns>
              <Column className="" backgroundColor="primary">
                <Typography
                  variant="h1"
                  color="light"
                  style={{ color: 'white' }}
                >
                  Aetna<sup>®</sup> Medicare Direct
                </Typography>
                <Typography
                  variant="h5"
                  color="light"
                  style={{ color: 'white' }}
                >
                  The direct way to compare available Medicare Advantage and
                  prescription plans in just 3 easy steps!
                </Typography>
              </Column>
              <Column
                className="card text-align-left-small"
                backgroundColor="light"
              >
                <Typography variant="h5">
                  <strong>Step 1:</strong>{' '}
                  <span className="font-weight-normal">
                    Input your ZIP code below
                  </span>
                </Typography>
                <div className="availability-form">
                  <AvailabilityForm
                    placeholderText="ZIP Code"
                    buttonText="Next Step"
                    buttonColor="primary"
                    butttonBackgroundColor="black"
                    className="avail-form"
                  />
                </div>
                <Typography variant="h5">
                  <strong>Step 2:</strong>{' '}
                  <span className="font-weight-normal">
                    Select preferred doctors and prescriptions
                  </span>
                </Typography>
                <Typography variant="h5">
                  <strong>Step 3:</strong>{' '}
                  <span className="font-weight-normal">
                    Compare plans in your area
                  </span>
                </Typography>
              </Column>
            </Columns>
          </Stack>
        </VariableContent>

        <CountdownTimer />

        <VariableContent
          backgroundColor="lightGray"
          alignMainContent="center"
          mainContent={
            <Typography variant="h2">
              The steps to enroll in a Medicare plan online
            </Typography>
          }
        >
          <Stack>
            <Columns>
              <Column className="card center-content bg-light">
                <div className="top-content">
                  <StaticImage
                    src="../../images/icons/purple-mailbox.svg"
                    alt="mailbox icon"
                    layout="fixed"
                    className="product-icons"
                  />
                  <Typography variant="h5" color="primary">
                    Step 1
                  </Typography>
                  <Typography variant="body">
                    Type in your ZIP code to see Medicare options in your area.
                  </Typography>
                </div>
              </Column>
              <Column className="card center-content bg-light">
                <div className="top-content">
                  <StaticImage
                    src="../../images/icons/purple-stethoscope.svg"
                    alt="stethoscope icon"
                    layout="fixed"
                    className="product-icons"
                  />
                  <Typography variant="h5" color="primary">
                    Step 2
                  </Typography>
                  <Typography variant="body">
                    Share your preferred doctors and prescriptions for more
                    tailored results.
                  </Typography>
                </div>
              </Column>
              <Column className="card center-content bg-light">
                <div className="top-content">
                  <StaticImage
                    src="../../images/icons/purple-cards.svg"
                    alt="cards icon"
                    layout="fixed"
                    className="product-icons"
                  />
                  <Typography variant="h5" color="primary">
                    Step 3
                  </Typography>
                  <Typography variant="body">
                    Compare Medicare Advantage and Part D plans in your area
                    that may be right for you!
                  </Typography>
                </div>
              </Column>
            </Columns>
          </Stack>
        </VariableContent>

        <SplitContent
          id="medicare-explanation"
          backgroundColor="light"
          alignImageToBottom={false}
          image={
            <img
              src={womanLaptopWriting}
              alt="an elderly woman writes in a spiral notebook while looking at laptop"
            />
          }
          mainContent={
            <>
              <Typography variant="h2">
                Directly Enroll in Medicare Online or Over the Phone
              </Typography>
              <Typography variant="body">
                When you enroll in Medicare online, you have all the tools at
                your disposal to compare and find the right Aetna Medicare plan
                for you. If you need additional help, call one of our expert
                licensed agents across the country for answers to Medicare
                questions you have—the choice is yours!
              </Typography>
              <LinkButton
                variant="feature"
                to={`tel:${rotatedNumber}`}
                color="primary"
                className="margin-x-auto"
                requestId={requestId}
              >
                Call Now! {rotatedNumber}
              </LinkButton>
            </>
          }
        />
      </>
    ),
  }

  return <Layout {...layoutProps} />
}

export default Home

export const query = graphql`
  query HomeQuery {
    allBrandyDisclaimer {
      edges {
        node {
          brandy_id
          text
          symbol
        }
      }
    }
  }
`
